/**
 * 文書管理 新規登録系画面定義ファイル
 * 概要
 * 画面単位でのフロント定義について記載する
 * 更新履歴
 * 2022/02/28 追加 尾坂
 */

/** 自動生成で作成したフィールドについて、自動生成だけでは賄えない設定（主にデータベース参照型）を記載する **/
const displayFormSetting = [
  {
    display_id: 'employee_id',
    component: 'MasterSelectControl',
    dblookup: 'm_employee',
    props: {
      leftSideInputName: 'employee_id',
      rightSideInputName: 'name',
      masterId: 'id',
      dialogTitle: '社員選択',
      label: '登録者',
      columns: [],
      body: [],
      isRightSideInputReadOnly: true,
      hideDataConditions: [
        {
          fieldId: 'Status',
          value: ['アカウント無効', '削除済']
        }
      ]
    }
  },
  {
    display_id: 'document_category',
    component: 'MasterSelectControl',
    dblookup: 'document_category',
    props: {
      leftSideInputName: 'document_category',
      rightSideInputName: 'category_name',
      masterId: 'category_id',
      dialogTitle: 'カテゴリ選択',
      label: 'カテゴリ',
      columns: [],
      body: [],
      isRightSideInputReadOnly: true,
      hideDataConditions: [
        {
          fieldId: 'Status',
          value: ['無効']
        }
      ]
    }
  },
  {
    display_id: 'doc_id',
    props: {
      isReadonly: true,
      isDisabled: true
    }
  }
];

/** 登録ガイドメッセージ */
const authorityValidMessage = 'この内容でよろしければ「登録する」を押してください';
const authorityInvalidMessage = '登録権限を持たないユーザです';

/** 登録・編集完了時の確認ダイアログ */
const registerFinishText = '登録が完了いたしました';
const registerFinishAction = [
  {
    id: 1,
    color: 'primary',
    type: 'routing',
    name: 'toEdit',
    value: '詳細画面へ',
    route: '/doc_edit/:id'
  }
];

export default {
  displayFormSetting,
  authorityValidMessage,
  authorityInvalidMessage,
  registerFinishText,
  registerFinishAction
};
