

































































/** デコレータ利用のためComponent, Vueをimport **/
import { Component, Vue } from 'vue-property-decorator';
/** 画面で必要なVuexの各stateをimport **/
import hexabaseState from '@/store/hexabase';
import searchState from '@/store/search';
/** ドメイン単位の設定ファイルから必要な設定をimport **/
import {
  documentDBName,
  documentDBDeleteStatusName,
  documentDBStatusDisplayId,
  documentDBNotSearchStatusId,
  documentEditPath,
  documentCategoryDBDeleteStatusName
} from '@/domains/documentManagement/constants/constants';
/** 画面単位の設定ファイルから必要な設定をimport **/
import localDisplayConfig from '@/domains/documentManagement/constants/documentList';
/** 画面で利用するモデルファイルをimport **/
import DocumentModel from '@/domains/documentManagement/models/documentModel';
/** 画面で利用するバックエンド設定のimport **/
import {
  DatastoreFields,
  FieldChildrenProp,
  GetItemSearchRes,
  GetItemsResponse,
  Items
} from '@/services/hexabase/types.d';
/** 画面で利用するコンポーネントをimport 下部に存在する＠Componentにも記載する **/
import Header from '@/components/common/modules/Header.vue';
import SideMenu from '@/components/common/modules/SideMenu.vue';
import PageInfoBar from '@/components/common/modules/PageInfoBar.vue';
import SearchList from '@/components/common/modules/list/SearchList.vue';
import SelectDialog from '@/components/common/modules/SelectDialog.vue';
import DocumentSearchPanel from '@/domains/documentManagement/components/DocumentSearchPanel.vue';
import DialogData from '@/components/common/modules/DialogData.vue';
import { SelectOption, SelectProps } from '@/constants/form/types';

@Component({
  components: {
    Header,
    SideMenu,
    PageInfoBar,
    SearchList,
    SelectDialog,
    DocumentSearchPanel,
    DialogData
  }
})
export default class DocumentList extends Vue {
  // 各種設定値をリテラル宣言（ ※コピー後、必要に応じてリテラル値を変更 ）
  readonly baseTranDatabaseName = documentDBName; // 対象のデータベース名(ja)
  readonly removeStatusName = documentDBDeleteStatusName; // 検索条件のステータス・コンボボックスの項目から省くステータス
  readonly initializeDescriptiveText = localDisplayConfig.initializeDescriptiveText; // 選択件数表示パネル用・ガイダンス初期値
  readonly buttonItems = localDisplayConfig.buttonItems; // 検索結果で使用するボタンアイテム
  readonly imageItems = localDisplayConfig.imageItems; // 検索結果で使用する画像アイテム
  // ===============================================================
  // モデルをセット
  readonly basicModel = new DocumentModel();
  // アプリIDをセット
  readonly projectId = hexabaseState.applicationId;
  // dbID格納プロパティ
  mainDsId = '';
  // マスタデータ関連格納プロパティ
  masterData: { [k: string]: { [k: string]: Array<{ [k: string]: string }> } } = {};
  // アイテム取得時Param
  payload = {
    conditions: [] as Array<{ [key: string]: string | string[] | boolean }>,
    per_page: localDisplayConfig.listPerPage,
    page: 1,
    use_display_id: true,
    include_links: true,
    include_lookups: true,
    sort_fields: localDisplayConfig.DefaultSort
  };
  // 各入力フィールド情報格納プロパティ
  searchFieldsData: Array<FieldChildrenProp> = [];
  searchFieldCols = '';
  statusList: Array<GetItemSearchRes> = [];
  // 一覧表示部分に使用
  fieldsData: DatastoreFields = {
    field_layout: {},
    fields: {}
  };
  apiResponseData: GetItemsResponse = { items: [], totalItems: 0 };
  colums: Array<{ [k: string]: string | number }> = [];
  // ページネーション情報
  page = {
    currentPage: 1,
    totalPage: 1,
    totalItem: 0,
    visible: 8,
    perPage: localDisplayConfig.listPerPage
  };
  apiResult = false;
  // 検索結果コンポーネント用プロパティ
  isFlush = false;
  selectItems: Array<Items> = []; // 一括処理に渡すアイテム情報

  // 選択件数表示パネル設定用
  numberOfSelection = 0;
  descriptiveText = this.initializeDescriptiveText;

  // 一括カテゴリ設定ダイアログのタイトル設定
  selectDialogTitle = localDisplayConfig.selectCategoryDialogTitle;
  // 一括カテゴリ設定ダイアログの開閉ステータス
  selectDialog = false;
  // 一括カテゴリ設定ダイアログのボタン
  selectDialogButtonOkDisplay = true;
  selectDialogButtonOkName = localDisplayConfig.selectCategoryDialogButtonOkName;
  selectDialogButtonNgName = localDisplayConfig.selectCategoryDialogButtonNgName;
  // 一括カテゴリ設定ダイアログのメッセージ
  selectDialogMessage = '';
  // 一括カテゴリ設定ダイアログのデータ
  documentCategoryData: SelectProps = {
    name: '',
    options: []
  };

  // 詳細ダイアログ用プロパティ
  detailColumns: Array<{ [k: string]: string | any }> = [];
  detailDialogStatus = false;
  detailItems: Array<{ [k: string]: string | any }> = [];
  detailDialogTitle = '';

  async created() {
    try {
      // ローディングをセット
      hexabaseState.setIsLoading(true);
      // データストア情報セット
      this.mainDsId = hexabaseState.datastoreIds[this.baseTranDatabaseName];
      const [fieldsData, statusList] = await Promise.all([
        this.basicModel.getField(this.projectId, this.mainDsId),
        this.basicModel.getStatusList(this.projectId, this.mainDsId)
      ]);
      // マスターデータを取得しグローバル変数に格納
      await this.basicModel.getMasterData();
      this.masterData = this.basicModel.getMaster();
      // 自動生成コンポーネント用にプロパティー設定
      this.fieldsData = fieldsData;
      this.searchFieldsData = this.basicModel.createSearchConf(
        this.fieldsData,
        this.masterData,
        localDisplayConfig.searchForm
      );
      this.searchFieldCols = `${localDisplayConfig.SearchPanelColumnWidth}% `.repeat(
        100 / localDisplayConfig.SearchPanelColumnWidth
      ); //検索フォームは表示を均等にするため固定
      // ステータス検索用にステータスリストをマージ
      this.statusList = statusList;
      if (this.statusList.length > 0) {
        const deleteStatusIndex = this.statusList[0].statuses!.findIndex(
          status => status.status_name === this.removeStatusName
        );
        if (deleteStatusIndex > 0) {
          this.statusList[0].statuses!.splice(deleteStatusIndex, 1);
        }
      }

      // 一括カテゴリ更新ダイアログ用にカテゴリ情報を抽出してセット
      this.masterData['document_category'].body.forEach(item => {
        if (item.Status === documentCategoryDBDeleteStatusName) {
          return;
        }
        const option: SelectOption = {
          text: item.title,
          value: item.i_id
        };
        this.documentCategoryData.options.push(option);
      });

      // 初期表示データ取得・作成
      // 検索Stateが存在していたら検索項目に値をセットする
      const stateCondition =
        searchState.searchCondition !== undefined ? searchState.searchCondition : [];
      for (const key in stateCondition) {
        const setConditionIndex = this.searchFieldsData.findIndex(
          field => field.display_id === stateCondition[key].id
        );
        if (setConditionIndex >= 0) {
          const dataType = this.searchFieldsData[setConditionIndex].dataType;
          switch (dataType) {
            case 'dslookup':
              this.searchFieldsData[setConditionIndex].props!.value = {
                item_id: stateCondition[key].search_value[0]
              };
              break;
            case 'status':
              this.searchFieldsData[setConditionIndex].props!.value =
                stateCondition[key].search_value[0];
              break;
            case 'datetime':
              this.searchFieldsData[setConditionIndex].props!.valueFrom =
                stateCondition[key].search_value[0];
              this.searchFieldsData[setConditionIndex].props!.valueTo =
                stateCondition[key].search_value[1];
              break;
            default:
              this.searchFieldsData[setConditionIndex].props!.value =
                stateCondition[key].search_value[0];
              break;
          }
        }
      }

      // 検索条件を与えられている場合、検索結果を取得
      if ('searchConditions' in this.$route.query) {
        await this.getItems(stateCondition);
      } else {
        this.apiResult = true;
      }

      // 一覧カラム設定
      this.colums = this.basicModel.createColumns(
        localDisplayConfig.ColumnList,
        this.fieldsData.fields
      );
      this.colums.map(v => {
        if (v.name === documentDBStatusDisplayId) {
          v.text = 'ステータス';
        }
        return v;
      });
    } catch (e) {
      this.setError(e as string | object);
    } finally {
      hexabaseState.setIsLoading(false);
    }
  }

  /**
   * 一覧表示するアイテムを取得する
   * @param {array} condition - 検索条件を指定する
   * @param {number} page - 検索オフセット情報
   * @returns {void} 各内部プロパティに情報セット
   */
  async getItems(condition?: Array<{ [key: string]: string[] }>, page?: number) {
    if (condition !== undefined) {
      for (const key in searchState.searchCondition) {
        const findData = condition.find(
          recode => recode.id === searchState.searchCondition![key].id
        );
        if (!findData) {
          condition.push(searchState.searchCondition[key]);
        } else {
          const setConditionIndex = this.searchFieldsData.findIndex(
            field => field.display_id === searchState.searchCondition[key].id
          );
          if (setConditionIndex >= 0) {
            const componentType = this.searchFieldsData[setConditionIndex].component;
            switch (componentType) {
              case 'DatePickerRangeArea':
                if (searchState.searchCondition[key].search_value[0] && !findData.search_value[0]) {
                  findData.search_value[0] = searchState.searchCondition[key].search_value[0];
                }
                if (searchState.searchCondition[key].search_value[1] && !findData.search_value[1]) {
                  findData.search_value[1] = searchState.searchCondition[key].search_value[1];
                }
                break;
              default:
                break;
            }
          }
        }
      }
      searchState.setConditions(condition);
    }
    this.apiResult = false;
    if (condition !== undefined) {
      // conditionsに固定条件を付与するためconcatで参照渡しを回避する
      this.payload['conditions'] = condition.concat();
    }

    // 検索で不要なステータスを検索条件に追加し、検索結果から除外する
    this.payload['conditions'].push({
      id: documentDBStatusDisplayId,
      search_value: documentDBNotSearchStatusId,
      not_match: true
    });
    this.payload['page'] = page !== undefined ? page : 1;

    try {
      // ローディングをセット
      hexabaseState.setIsLoading(true);
      const fieldValue = await this.basicModel.getItemSearch(
        this.projectId,
        this.mainDsId,
        this.payload
      );

      // 定義ファイルで指定した日付の表示形式変更
      for (const key in fieldValue.items) {
        for (const index in localDisplayConfig.dateItems) {
          if (
            fieldValue.items[key][localDisplayConfig.dateItems[index].name] !== undefined &&
            fieldValue.items[key][localDisplayConfig.dateItems[index].name] !== null &&
            fieldValue.items[key][localDisplayConfig.dateItems[index].name].length > 0
          ) {
            const date = fieldValue.items[key][localDisplayConfig.dateItems[index].name];
            fieldValue.items[key][
              localDisplayConfig.dateItems[index].name
            ] = this.basicModel.editDatetype(
              date,
              'Asia/Tokyo',
              localDisplayConfig.dateItems[index].format
            );
          }
        }
      }

      this.apiResponseData = fieldValue;
    } catch (e) {
      this.setError(e as string | object);
    } finally {
      hexabaseState.setIsLoading(false);
      this.apiResult = true;
    }

    // アイテム数等をセット
    (this.page.totalItem = this.apiResponseData.totalItems), localDisplayConfig.listPerPage;
    this.page.totalPage = this.basicModel.getTotalPage(
      this.apiResponseData.totalItems,
      localDisplayConfig.listPerPage
    );
    this.page.currentPage = this.payload['page'];
  }

  /**
   * 検索パネルにて「リセット」ボタン押下時
   * 内部プロパティの検索情報を消去する
   * @returns void 内部プロパティ消去
   */
  resetSearchForm(): void {
    this.payload.conditions.splice(0);
    this.payload.page = 1;
  }

  /**
   * ページネーションボタンが押下された際
   * 指定ページの情報を問い合わせに行く
   * @param {number} response - 子コンポーネントから渡ってきたページ番号
   * @returns void API問い合わせ
   */
  pageNaviAction(response: number) {
    this.getItems(undefined, response);
  }

  /**
   * 検索結果レコードのチェックボックスハンドリング
   * selectItemsで受け取り、件数表示へ選択件数を渡す
   * @param selectItems - 選択レコード情報
   * @return void - 内部プロパティ変更
   */
  selectItemHandle(selectItems: any) {
    this.numberOfSelection = selectItems.length;
    this.selectItems.splice(0);
    this.selectItems.push(...selectItems);
  }

  /**
   * 明細行のボタンが押下された際の処理
   * @param item - 選択レコード情報
   * @return void - 内部プロパティ変更
   */
  onClickDetailButton(item: any) {
    if (item.buttonName === 'revision_history' || item.buttonName === 'remarks') {
      // 改訂履歴・備考ボタン押下時に詳細ダイアログを表示
      const detailColumns = localDisplayConfig.detailDialogs[item.buttonName]['detailColumns'];
      const detailDialogTitle = localDisplayConfig.detailDialogs[item.buttonName]['detailtTitle'];
      this.openDetailDialog(detailColumns, detailDialogTitle, item);
    }
  }

  /**
   * 行を選択した際のイベントハンドリング
   * @param rowData 選択肢た行のデータ
   */
  onClickItemRow(rowData: any) {
    this.$router.push({ path: `/${documentEditPath}/${rowData.i_id}`, query: { searchConditions: 'true' }});
  }

  /**
   * 詳細ダイアログの表示処理
   */
  openDetailDialog(detailColumns: Array<{ [k: string]: string | any }>, title: string, item: any) {
    this.detailColumns = detailColumns;
    this.detailDialogStatus = true;
    this.detailItems = [item.record];
    this.detailDialogTitle = title;
  }

  /**
   * 詳細ダイアログにて閉じるボタンが押下された際の処理
   */
  detailClose() {
    this.detailDialogStatus = false;
    this.detailItems.splice(0);
  }

  /**
   * API実行結果から受け取ったエラーメッセージを共通のエラーダイアログに表示する
   * @param errorMessage {string | object } - API実行結果から受け取ったエラーメッセージ
   * @returns {void}
   */
  setError(errorMessage: string | object): void {
    if (typeof errorMessage === 'object') errorMessage = this.basicModel.getDefaultErrorMessage();
    // エラーをキャッチした際共通エラーダイアログにセット
    this.$store.commit('hexabase/setIsError', true);
    this.$store.commit('hexabase/setErrorMessage', errorMessage);
  }

  /**
   * 一括カテゴリ設定モーダルでの更新ハンドリング
   * @param categoryId {string} - 選択したカテゴリーID
   */
  async selectCategory(categoryId: string) {
    // 一括カテゴリ更新ダイアログを閉じる
    this.selectDialog = false;
    // ローディングをセット
    hexabaseState.setIsLoading(true);

    // 送信データ
    const sendData = {
      item: {
        document_category: ''
      },
      rev_no: 0
    };

    await Promise.all(
      this.selectItems.map(async item => {
        sendData.item.document_category = categoryId;
        sendData.rev_no = Number(item.rev_no);
        await this.basicModel.updateItems(
          hexabaseState.applicationId,
          this.mainDsId,
          item.i_id,
          sendData
        );
        item.rev_no = String(Number(item.rev_no) + 1);
      })
    );

    hexabaseState.setIsLoading(false);

    // 現在と同一の条件で再検索
    this.getItems(undefined, undefined);
  }

  /**
   * 一括カテゴリ設定モーダル表示ボタン押下時
   * @return void - 内部プロパティ変更
   */
  categoryUpdateDialogOpen(): void {

    if(this.selectItems.length == 0){
      // 文書が選択されていない場合はエラーメッセージを設定
      this.selectDialogMessage = localDisplayConfig.selectCategoryDialogErrorMessageNoDocument;
      this.selectDialogButtonOkDisplay = false;
      this.selectDialogButtonNgName = localDisplayConfig.selectCategoryDialogErrorButtonNgName;
    }else{
      this.selectDialogMessage = '';
      this.selectDialogButtonOkDisplay = true;
      this.selectDialogButtonNgName = localDisplayConfig.selectCategoryDialogButtonNgName;
    }

    this.selectDialog = true;
  }

  /**
   * 一括カテゴリ設定モーダルにて閉じるボタン押下時
   * @return void - 内部プロパティ変更
   */
  closeDialog(): void {
    this.selectDialog = false;
  }
}
