/**
 * 文書管理 検索画面定義ファイル
 * 概要
 * 画面単位でのフロント定義について記載する
 * 更新履歴
 * 2022/02/28 追加 尾坂
 */

/** 検索結果の1ページあたりに表示するレコード数を指定する **/
const listPerPage = 10;
/**
 * 検索画面の検索項目について定義する
 * ※データベース参照型を利用する場合は明示的な指定が必要となる
 * グリッド指定で表示位置を指定する
 * col: X座標, row: Y座標, sizeX: 横幅, sizeY: 縦幅
 */
const searchForm = [
  {
    display_id: 'create_date',
    component: 'DatePickerRangeArea',
    props: {
      nameFrom: 'create_date',
      nameTo: 'create_date'
    },
    col: 2,
    row: 1,
    sizeX: 6,
    sizeY: 1
  },
  {
    display_id: 'employee_id',
    component: 'MasterSelectControl',
    col: 1,
    dblookup: 'm_employee',
    props: {
      leftSideInputName: 'employee_id',
      rightSideInputName: 'name',
      masterId: 'id',
      dialogTitle: '社員選択',
      label: '登録者',
      columns: [],
      body: [],
      isRightSideInputReadOnly: true,
      hideDataConditions: [
        {
          fieldId: 'Status',
          value: ['アカウント無効', '削除済']
        }
      ]
    },
    row: 0,
    sizeX: 6,
    sizeY: 1
  },
  {
    display_id: 'document_category',
    component: 'MasterSelectControl',
    dblookup: 'document_category',
    col: 0,
    row: 0,
    sizeX: 6,
    sizeY: 1,
    props: {
      leftSideInputName: 'document_category',
      rightSideInputName: 'category_name',
      masterId: 'category_id',
      dialogTitle: 'カテゴリ選択',
      label: 'カテゴリ',
      columns: [],
      body: [],
      isRightSideInputReadOnly: true,
      hideDataConditions: [
        {
          fieldId: 'Status',
          value: ['無効']
        }
      ]
    }
  },
  {
    display_id: 'doc_name',
    col: 3,
    row: 1,
    sizeX: 6,
    sizeY: 1
  }
];

/** 一覧取得時のデフォルトソート **/
const DefaultSort: Array<{ [k: string]: string }> = [{ id: 'doc_id', order: 'desc' }];

/** 検索画面 検索結果表示テーブルカラム **/
const ColumnList: Array<{ [k: string]: string | number }> = [
  { name: 'doc_id', order: 1 },
  { name: 'doc_name', order: 2 },
  { name: 'document_category', order: 3 },
  { name: 'employee_id', order: 4 },
  { name: 'create_date', order: 5, width: 200 },
  { name: 'revision_history', order: 6, width: 50 },
  { name: 'remarks', order: 7, width: 50 }
];

// 検索結果内でボタンを表示するColumn
const buttonItems = [
  { name: 'revision_history', text: '改訂履歴', alwaysDisplayed: true, class: 'btn-normal' },
  { name: 'remarks', text: '備考', alwaysDisplayed: true, class: 'btn-normal' }
];

const imageItems = [
  {
    name: 'document_category',
    isText: true,
    isRegex: false,
    defaultSrc: require('@/assets/img/category-others.svg'),
    imageList: [
      {
        key: '財務',
        src: require('@/assets/img/category-zaimu.svg')
      },
      {
        key: '経理',
        src: require('@/assets/img/category-keiri.svg')
      },
      {
        key: '内部統制',
        src: require('@/assets/img/category-naibu.svg')
      },
      {
        key: '人事',
        src: require('@/assets/img/category-jinji.svg')
      },
      {
        key: 'その他',
        src: require('@/assets/img/category-others.svg')
      }
    ],
    props: {
      maxHeight: 30,
      maxWidth: 30
    }
  }
];

// 検索結果内で日付の表示形式変更する必要のある項目
// formatが空の場合、SQLDate(yyyy-MM-dd)フォーマットとなる
const dateItems: Array<{ [k: string]: string }> = [{ name: 'create_date', format: 'yyyy-MM-dd' }];

const detailDialogs: { [k: string]: any } = {
  revision_history: {
    detailColumns: [{ text: '改訂履歴', value: 'revision_history' }],
    detailtTitle: '改訂履歴'
  },
  remarks: { detailColumns: [{ text: '備考', value: 'remarks' }], detailtTitle: '備考' }
};

/** 検索パネルのGrid1区間の横幅（%） **/
const SearchPanelColumnWidth = 5;

const SearchPanelUnderButtonArray = [
  {
    name: 'search',
    color: 'primary',
    value: '検索'
  },
  {
    name: 'clear',
    color: 'clearbtn',
    value: 'クリア'
  }
];

const SearchPanelUnderSubButtonArray = [
  {
    name: 'category',
    color: 'categorybtn',
    value: 'カテゴリを変更する'
  }
];



/** 検索時の初期表示Option **/
const StatusSelectInitialOption: { text: string; value: string[] } = {
  text: '全ステータス',
  value: []
};
/** 検索結果からの一括処理時の各メッセージ **/
const bulkMessage: { [k: string]: string } = {
  noItems: 'アイテムを選択してください',
  notMatch: 'アイテムステータスが一致していません',
  apiSuccess: '処理が完了しました',
  apiError: '以下のアイテムの処理が失敗いたしました'
};
/** 画面中央ガイダンス表示部分の文言を指定 **/
const initializeDescriptiveText =
  'ここにガイダンス文言を設定できます。\n' + 'ここにガイダンス文言を設定できます。';

/** 一括カテゴリ設定ダイアログ **/
const selectCategoryDialogTitle = '一括カテゴリ設定';
const selectCategoryDialogButtonOkName = '更新';
const selectCategoryDialogButtonNgName = 'キャンセル';
const selectCategoryDialogErrorMessageNoDocument = '対象の文書を選択してください。';
const selectCategoryDialogErrorButtonNgName = '閉じる';

export default {
  listPerPage,
  searchForm,
  DefaultSort,
  ColumnList,
  dateItems,
  buttonItems,
  imageItems,
  detailDialogs,
  SearchPanelColumnWidth,
  SearchPanelUnderButtonArray,
  SearchPanelUnderSubButtonArray,
  StatusSelectInitialOption,
  bulkMessage,
  initializeDescriptiveText,
  selectCategoryDialogTitle,
  selectCategoryDialogButtonOkName,
  selectCategoryDialogButtonNgName,
  selectCategoryDialogErrorMessageNoDocument,
  selectCategoryDialogErrorButtonNgName
};
