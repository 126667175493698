






















import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';

@Component({})
export default class Pagination extends Vue {
  // カレントページ数
  private page = 1;
  // カレントページ数
  @Prop({ default: 1 })
  private value?: number;
  // トータルページ数
  @Prop({ default: 1 })
  private length?: number;
  // 表示ページネーション数
  @Prop({ default: 1 })
  private totalVisible?: number;
  // ダークテンプレートカラー
  @Prop({ default: false })
  private dark?: boolean;
  // 見た目を丸くする
  @Prop({ default: false })
  private isCircle?: boolean;
  // 押下制御
  @Prop({ default: false })
  private isDisabled?: boolean;

  /**
   * 外部からのValue値を監視しv-modelに連携
   */
  @Watch('value')
  watchValue() {
    if (this.value !== undefined) {
      this.page = this.value;
    }
  }

  /**
   * 親コンポーネントに押下したか否か通知する
   * @module isPushPage
   * @param  {number} - 押下したページ番号
   * @return {number} - 押下したページ番号
   */
  @Emit('isPushPage')
  public isPushPage(page: number): number {
    return page;
  }
}
