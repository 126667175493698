/**
 * 文書管理 詳細・編集画面定義ファイル
 * 概要
 * 画面単位でのフロント定義について記載する
 * 更新履歴
 * 2022/02/28 追加 尾坂
 */

/** 自動生成で作成したフィールドについて、自動生成だけでは賄えない設定（主にデータベース参照型）を記載する **/
const displayFormSetting = [
  {
    display_id: 'employee_id',
    component: 'MasterSelectControl',
    dblookup: 'm_employee',
    props: {
      leftSideInputName: 'employee_id',
      rightSideInputName: 'name',
      masterId: 'id',
      dialogTitle: '社員選択',
      label: '登録者',
      columns: [],
      body: [],
      isRightSideInputReadOnly: true,
      isLeftSideInputReadOnly: true,
      isRightSideInputDisabled: true,
      isLeftSideInputDisabled: true,
      hideDataConditions: [
        {
          fieldId: 'Status',
          value: ['アカウント無効', '削除済']
        }
      ]
    }
  },
  {
    display_id: 'document_category',
    component: 'MasterSelectControl',
    dblookup: 'document_category',
    props: {
      leftSideInputName: 'document_category',
      rightSideInputName: 'category_name',
      masterId: 'category_id',
      dialogTitle: 'カテゴリ選択',
      label: 'カテゴリ',
      columns: [],
      body: [],
      isRightSideInputReadOnly: true,
      isLeftSideInputReadOnly: true,
      isRightSideInputDisabled: true,
      isLeftSideInputDisabled: true,
      hideDataConditions: [
        {
          fieldId: 'Status',
          value: ['無効']
        }
      ]
    }
  },
  {
    display_id: 'file_id',
    props: {
      actionName: 'update_item',
      areaClass: 'basic-info__remark',
      buttonLabel: '添付ファイル 登録/参照',
      buttonSubmitColor: 'primary',
      buttonSubmitText: '実行',
      fieldId: 'file_id',
      modalCaution:
        '上記の内容が添付ファイルとして管理されます。よろしければ実行ボタンを押してください。',
      isCancel: true,
      isModal: true,
      isSubmit: true,
      isDisabled: false,
      placeholder: 'ファイルを選択してください',
      title: '添付ファイル 登録/参照',
      imageItems: [
        {
          name: 'name',
          isText: true,
          isRegex: true,
          defaultSrc: require('@/assets/img/file-others.svg'),
          imageList: [
            {
              key: '.+\\.pdf$',
              src: require('@/assets/img/file-pdf.svg')
            },
            {
              key: '(.+\\.ppt$)|(.+\\.pptx$)',
              src: require('@/assets/img/file-pp.svg')
            },
            {
              key: '(.+\\.doc$)|(.+\\.docx$)',
              src: require('@/assets/img/file-word.svg')
            },
            {
              key: '(.+\\.xls$)|(.+\\.xlsx$)|(.+\\.xlsm$)',
              src: require('@/assets/img/file-xl.svg')
            }
          ],
          props: {
            maxHeight: 30,
            maxWidth: 30
          }
        }
      ]
    }
  },
  {
    display_id: 'doc_id',
    props: {
      isReadonly: true,
      isDisabled: true
    }
  },
  {
    display_id: 'create_date',
    props: {
      format: 'yyyy-MM-dd'
    }
  }
];
/** ステータスが存在しないテーブルを元とする画面の許可アクション **/
const allowActionPrefix = '';
/** 詳細の編集完了後ダイアログ定義 **/
const editFinishText = 'xxx情報を編集しました';
const editFinishAction = [
  {
    id: 1,
    color: 'primary',
    type: 'reload',
    name: 'reload',
    value: '閉じる'
  }
];
const deleteFinishAction = [
  {
    id: 1,
    color: 'primary',
    type: 'routing',
    route: '/doc_list',
    name: 'delete',
    value: '閉じる'
  }
];

// keyの項目を選択した際に、targetの項目を絞り込むための定義
// 例：顧客を選択して、顧客部署が絞り込まれる等
const filterSelect: {
  key: string;
  target: string;
}[] = [
  // { key: 'supplier_id', target: 'supplier_staff_id' },
  // { key: 'payment_destination_supplier_id', target: 'payment_information_id' }
];

export default {
  displayFormSetting,
  allowActionPrefix,
  editFinishText,
  editFinishAction,
  deleteFinishAction,
  filterSelect
};
