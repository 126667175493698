





















































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { FieldChildrenProp } from '@/services/hexabase/types';
import localDisplayConfig from '@/domains/documentManagement/constants/documentList';
import Button from '@/components/common/elements/Button.vue';
import SingleText from '@/components/common/elements/SingleText.vue';
import IconTextLink from '@/components/common/elements/IconTextLink.vue';
import Icon from '@/components/common/elements/Icon.vue';
import AutoCreateForm from '@/components/form/AutoCreateForm.vue';
@Component({
  components: { IconTextLink, Icon, SingleText, Button, AutoCreateForm }
})
export default class DocumentSearchPanel extends Vue {
  // ボタン
  underButtonArray = localDisplayConfig.SearchPanelUnderButtonArray;
  // サブボタン
  underSubButtonArray = localDisplayConfig.SearchPanelUnderSubButtonArray;
  // 画面開閉状態
  private switchButtonValue?: boolean = true;
  // 開閉状態アイコン
  private switchButtonIcon?: string = 'mdi-menu-up';
  // 検索条件に表示するエレメント情報
  @Prop({ default: () => [] })
  private fieldData?: Array<FieldChildrenProp>;
  // 検索のGridStyle値
  @Prop({ default: '' })
  private fieldCols?: string;
  // 検索要素のデータタイプ
  @Prop({ default: () => ({}) })
  private fieldTypes?: { [k: string]: string };
  // 検索条件（このコンポーネントで整形するため）
  private searchConditions: Array<{ [key: string]: string | string[] }> = [];
  // 検索項目が変更された場合、フォームをリセットする（検索条件として残ってしまうため）
  @Watch('fieldData')
  watchFieldData() {
    this.resetFormToParent();
  }
  /**
   * パネル開閉ボタン処理
   * @param {boolean} flag - パネル開閉状態フラグ
   * @return {void} - 内部プロパティ変更
   */
  private showDetailInformation() {
    if (this.switchButtonValue === true) {
      this.switchButtonValue = false;
      this.switchButtonIcon = 'mdi-menu-down';
    } else {
      this.switchButtonValue = true;
      this.switchButtonIcon = 'mdi-menu-up';
    }
  }
  /**
   * 子コンポーネントからEmitされる値を取得
   * 検索用Paramを作成し「searchConditions」に格納
   * @param {any} response - 子コンポーネントより渡される値
   * @return {void} - コンソールに出力
   */
  @Emit('catchInput')
  private catchInput(response: any) {
    if (response.value === undefined) return;
    const searchVal = this.createSearchConditionList(
      response,
      this.searchConditions,
      this.fieldTypes
    );
    const findIndex = this.searchConditions.findIndex(recode => recode.id === searchVal.id);
    if (findIndex === -1) {
      this.searchConditions.push(searchVal);
    } else {
      this.searchConditions[findIndex] = { ...searchVal };
      if (!this.isSearchValue(searchVal, response.range !== 'to' ? 0 : 1)) {
        this.searchConditions.splice(findIndex, 1);
      }
    }
  }
  private isSearchValue(searchVal: { [key: string]: string | string[] }, index: any) {
    return searchVal.search_value[index].length === 0 ? false : true;
  }
  /**
   * 検索条件作成処理
   * @param {object} input - 入力値
   * @param {array} searchConditions - 現状の検索条件
   * @param {object} typeList - 要素ごとの型
   * @returns {araay} 加工された検索条件
   */
  public createSearchConditionList(
    input: any, //{[k:string]: string},
    searchConditions: Array<{ [key: string]: string | string[] }>,
    typeList?: { [k: string]: string }
  ) {
    const condition: { [key: string]: any } = {};
    const type = typeList![input.name];
    switch (type) {
      case 'datetime': {
        condition['id'] = input.name;
        const range = input.range;
        const currentData = searchConditions.find(v => v.id === input.name);
        if (range && currentData === undefined) {
          if (range === 'from') {
            condition['search_value'] = [input.value, null];
          } else if (range === 'to') {
            condition['search_value'] = [null, input.value];
          }
        } else {
          condition['search_value'] = currentData!.search_value;
          const index = range === 'from' ? 0 : 1;
          condition['search_value'][index] = input.value;
        }
        break;
      }
      case 'number': {
        condition['id'] = input.name;
        condition['search_value'] = [input.value, input.value];
        break;
      }
      case 'dslookup': {
        condition['id'] = input.name;
        condition['search_value'] = [input.value];
        break;
      }
      case 'status': {
        condition['id'] = input.name;
        condition['search_value'] = typeof input.value === 'object' ? input.value : [input.value];
        break;
      }
      case 'text': {
        condition['id'] = input.name;
        condition['search_value'] = [input.value];
        condition['exact_match'] = false;
        break;
      }
      default: {
        condition['id'] = input.name;
        condition['search_value'] = [input.value];
        break;
      }
    }
    return condition;
  }
  /**
   * 押下されたボタンの種類により処理を分岐
   * @param {string} - 押下されたボタンの種類
   * @returns {void}
   */
  private buttonAction(type: string): void {
    if (type === 'search') {
      this.execSearch();
    } else if (type === 'clear') {
      this.resetForm();
    } else if (type === 'category') {
      this.categoryUpdate();
    }
  }
  /**
   * 検索パネルで「検索」ボタンが押下された際の処理
   * 検索処理を実行するため親コンポーネントに通知
   * @returns Emit - 検索条件通知
   */
  @Emit('execSearch')
  private execSearch() {
    return this.searchConditions;
  }
  /**
   * 検索パネルで「リセット」ボタンが押下された際の処理
   * v-formのrefを使用し入力削除
   * @returns {void} - 内部プロパティ変更
   */
  @Emit('resetForm')
  resetForm() {
    // refを使用しformをリセット
    const form: any = this.$refs.form;
    form.reset();
    // 内部プロパティの検索条件をクリア
    this.searchConditions.splice(0);
    return this.searchConditions;
  }
  resetFormToParent() {
    // refを使用しformをリセット
    const form: any = this.$refs.form;
    form.reset();
    // 内部プロパティの検索条件をクリア
    this.searchConditions.splice(0);
  }

  /**
   * 検索パネルで「カテゴリ設定」ボタンが押下された際の処理
   * @returns {boolean} - true
   */
  @Emit('categoryUpdate')
  categoryUpdate(): boolean {
    return true;
  }
}
