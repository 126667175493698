










































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import Button from '@/components/common/elements/Button.vue';
import SingleText from '@/components/common/elements/SingleText.vue';
import Pagination from '@/components/common/modules/Pagination.vue';
import CheckBoxTable from '@/components/common/modules/CheckBoxTable.vue';
import { Items } from '@/services/hexabase/types';
@Component({
  components: { Button, SingleText, Pagination, CheckBoxTable }
})
export default class SearchList extends Vue {
  // ページネーション情報
  @Prop({
    default: () => ({
      currentPage: 1,
      totalPage: 1,
      visible: 1
    })
  })
  private pageInfo?: { [k: string]: number };
  // 選択アイテム
  private selectedItem: Array<Items> = [];
  // 一覧内のチップ背景色 (色、カラム名)
  @Prop({ default: () => [] })
  private chipItems?: [];
  // 一覧内で遷移ボタンを表示するColumn
  @Prop({ default: () => [] })
  private buttonItems?: [];
  // 一覧内で画像を表示するColumn
  @Prop({ default: () => [] })
  private imageItems?: [];
  // データテーブルに表示するColumnリスト
  @Prop({ default: () => [] })
  private colums?: [];
  // データテーブルに表示するデータリスト
  @Prop({ default: () => [] })
  private data?: [];
  // ステータス更新動作キャッチ用フラグ
  @Prop({ default: false })
  private flush!: boolean;
  // チェックボックス項目の表示制御
  @Prop({ default: false })
  private showSelect?: boolean;
  // api結果フラグ
  @Prop({ default: false })
  private apiResult!: boolean;
  // 変更を感知したら内部選択プロパティを初期化
  // 選択済みアイテムを消去
  @Watch('flush')
  watchFlush(): void {
    if (this.flush) this.selectedItem.splice(0);
  }
  /**
   * 押下した行のアイテムIDを返却
   * @return {void} - Emit セレクトアイテムデータ
   */
  @Emit('clickRowOperation')
  clickRowOperation(data: any): Array<{ [k: string]: string }> {
    return data;
  }

  /**
   * 選択された情報を内部プロパティに格納
   * @param {any} response - 子コンポーネントより渡される選択アイテムリスト
   * @return {void} - 選択状態を格納
   */
  @Emit('selectItemHandle')
  private selectItemHandle(response: any) {
    this.selectedItem.splice(0);
    this.selectedItem.push(...response);
    return this.selectedItem;
  }
  /**
   * ページネーションの押下ページ番号をEmit
   * @param {any} response - 子コンポーネントより渡される値
   * @return {void} Emit
   */
  @Emit('pagenaviAction')
  private pagenaviAction(response: any) {
    return response;
  }
  /**
   * 明細ボタン押下時のレコード情報をEmit
   * @param {any} response - 子コンポーネントより渡される値
   * @returns {void} - ルーティング
   */
  @Emit('clickButton')
  private clickButton(response: any) {
    return response;
  }
  /**
   * ページネーション部分に表示する文言を生成します
   * @param {{[k: string]: number}} pageinfo - ページ情報
   * @param {number} dataCount - 既存アイテム数
   */
  private createPageNation(pageInfo: { [k: string]: number }, dataCount: number): string | null {
    if (pageInfo.totalItem === 0) return null;
    const initialNum = 1;
    const start =
      pageInfo.currentPage > initialNum
        ? (pageInfo.currentPage - initialNum) * pageInfo.perPage + initialNum
        : initialNum;
    const end = start - 1 + dataCount;
    return `全${pageInfo.totalItem}件中 ${start}件〜${end}件を表⽰`;
  }
}
