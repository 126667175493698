











































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import Label from '@/components/common/elements/Label.vue';
import Typography from '@/components/common/elements/Typography.vue';
import SelectArea from '@/components/common/modules/SelectArea.vue';
import { SelectProps } from '@/constants/form/types';

@Component({
  components: { Label, Typography, SelectArea }
})
export default class SelectDialog extends Vue {
  @Prop()
  private dialog = false;
  // ダイアログ内表示タイトル
  @Prop({ default: '' })
  private title!: string;
  // ダイアログのエリア外を押下した際にBOXを閉じるか
  @Prop({ default: true })
  private isPersistent?: boolean;
  // ダイアログ内ボタンをテキストに変更
  @Prop({ default: false })
  private isTextActionButton?: boolean;
  // ダイアログ内NGボタン文字
  @Prop({ default: 'キャンセル' })
  private buttonTextNG?: string;
  // ダイアログ内OKボタン文字
  @Prop({ default: 'OK' })
  private buttonTextOK?: string;
  // ダイアログ内NGボタン文字色
  @Prop({ default: 'light' })
  private buttonColorNG?: string;
  // ダイアログ内OKボタン文字色
  @Prop({ default: 'primary' })
  private buttonColorOK?: string;
  // OKボタンを表示するか
  @Prop({ default: true })
  private isDisplayOk?: boolean;
  // キャンセルボタンを表示するか
  @Prop({ default: true })
  private isDisplayCancel?: boolean;
  // エラーメッセージ等
  @Prop({ default: '' })
  private message?: string;
  // 入力要素の初期値
  @Prop({ default: '' })
  private value?: string;
  // 入力要素内プレースホルダ
  @Prop({ default: '' })
  private placeholder?: string;
  // 入力要素入力制限
  @Prop({ default: false })
  private isDisabled?: boolean;
  // 入力要素高さ
  @Prop({ default: '5' })
  private rows?: string;
  /** 選択データ */
  @Prop({ default: () => ({ name: '', options: [] }) })
  private selectData!: SelectProps;
  /** 選択されたデータのID */
  private selectedData = '';

  /**
   * 親コンポーネントにinput要素名と値を通知する
   * @module inputOperation
   * @param {[k: string]: string} data - input要素名と値
   * @return {[k: string]: string} data - input要素名と値
   */
  @Emit('inputOperation')
  public inputOperation(data: { [k: string]: string }) {
    return data;
  }

  onSelectData(selectDataId: string): void {
    this.selectedData = selectDataId;
  }

  @Emit('actionOK')
  public actionOK() {
    return this.selectedData;
  }

  @Emit('actionNG')
  public actionNG() {
    return false;
  }
}
