













































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import Typography from '@/components/common/elements/Typography.vue';
import Table from '@/components/common/elements/Table.vue';
import InputArea from '@/components/common/modules/InputArea.vue';

@Component({
  components: { Typography, InputArea, Table }
})
export default class DialogData extends Vue {
  // ダイアログ開閉状態
  @Prop({ default: false })
  private dialog?: boolean;
  // ダイアログ内表示タイトル
  @Prop({ default: '' })
  private title?: string;
  // ダイアログのエリア外を押下した際にBOXを閉じるか
  @Prop({ default: true })
  private isPersistent?: boolean;
  // ダイアログ内ボタンをテキストに変更
  @Prop({ default: false })
  private isTextActionButton?: boolean;
  // ダイアログ内OKボタン文字
  @Prop({ default: 'OK' })
  private buttonTextOK?: string;
  // ダイアログ内OKボタン文字色
  @Prop({ default: 'primary' })
  private buttonColorOK?: string;
  // 明細アイテム情報
  @Prop({ default: () => [] })
  private body?: Array<{ [k: string]: string }>;
  // 明細ラベル
  @Prop({ default: () => [] })
  private columns?: Array<{ [k: string]: string }>;
  // モーダル横幅
  @Prop({ default: '500' })
  private width?: string;
  // エラーメッセージ等
  @Prop({ default: '' })
  private message?: string;
  // ローディング
  @Prop({ default: true })
  private isLoading?: boolean;
  // no-data-table-text
  @Prop({ default: '' })
  private tableText?: string;
  // ヘッダー非表示
  @Prop({ default: false })
  private hideDefaultHeader?: boolean;

  /**
   * 親コンポーネントに押下したか否か通知する
   * @module addDetailsOpratipon
   * @return {AddDetailResponse} - 要素ステータス、押下判定
   */
  @Emit('confirmOpratipon')
  public confirmOpratipon(isAgree: boolean): { [k: string]: boolean } {
    return { dialog: false, isAgree: isAgree };
  }
}
